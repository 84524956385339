import { Injectable } from '@angular/core';
import { AuthRepository } from '@domain/repositories/auth.repository';
import { AuthenticationModel } from '@domain/models/auth.model';
import { AuthModel } from '@domain/models/login-model';
import { Observable, catchError, map, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthenticationEntity } from './entities/auth.entity';
import { ResponseData } from '@base/wrappers/response-data';

@Injectable({
  providedIn: 'root',
})
export class AuthImplRepository extends AuthRepository {
  private readonly baseAuthUrl: string;

  constructor(private readonly http: HttpClient) {
    super();
    this.baseAuthUrl = `${environment.uriBase}Auth/`;
  }
  override login(authModel: AuthModel): Observable<AuthenticationModel> {
    return this.http
      .post<ResponseData<AuthenticationEntity>>(
        `${this.baseAuthUrl}login`,
        authModel
      )
      .pipe(
        map((res) => {
          if (res.data.user.role !== 'Admin')
            throw new Error(
              `Acceso Denegado: No tienes los permisos necesarios para acceder a esta función.`
            );

          return res.data;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            return throwError(
              () => 'Correo electrónico o contraseña incorrectos'
            );
          }
          if (error.status === 403) {
            return throwError(() => 'Correo electrónico no verificado');
          }
          return throwError(() =>
            error.message == ''
              ? `Disculpa, no pudimos iniciar sesion, por favor intenta de nuevo`
              : error.message
          );
        })
      );
  }
  override refreshToken(refreshToken: string): Observable<AuthenticationModel> {
    console.log({refreshToken});

    return this.http
      .post(`${this.baseAuthUrl}refresh-token`, { refreshToken })
      .pipe(map((res: any) => res.data));
  }

  override recovery(authModel: AuthModel): Observable<boolean> {
    return this.http.post(`${this.baseAuthUrl}forgot-password`, authModel).pipe(
      map((res: any) => res.status),
      catchError((error: HttpErrorResponse) => {
        console.log(error.error);
        if (error.status === 400) {
          return throwError(() => 'El email no ha sido encontrado');
        }
        return throwError(
          () =>
            'Disculpa, no pudimos crear tu cuenta, por favor intenta de nuevo'
        );
      })
    );
  }
}
