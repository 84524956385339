import { Mapper } from '@base/mapper';
import { TravelPlanSummaryEntity } from '../entities/travel-plan-summary-entity';
import { TravelPlanSummaryModel } from '@domain/models/travel-plan-summary.model';

export class TravelPlanSummaryMapper extends Mapper<
  TravelPlanSummaryEntity,
  TravelPlanSummaryModel
> {
  override mapFrom(param: TravelPlanSummaryEntity): TravelPlanSummaryModel {
    return { ...param };
  }

  override mapTo(param: TravelPlanSummaryModel): TravelPlanSummaryEntity {
    throw new Error('Method not implemented.');
  }
}
