import { ActionReducerMap } from '@ngrx/store';
import {
  UsersPlansSummaryState,
  usersPlansSummaryReducer,
} from './reducers/users-plans-summary.reducer';
import { UsersPlansSummaryEffects } from './effects/users-plans-summary.effects';
import { LoaderState, loaderReducer } from './reducers/loader.reducer';
import { UserState, userReducer } from './reducers/user.reducer';
import { UsersEffects } from './effects/user.effects';
import { TravelPlansState, travelPlansReducer } from './reducers/travel-plans.reducer';
import { TravelPlanState, travelPlanReducer } from './reducers/travel-plan.reducer';
import { TravelPlansEffects } from './effects/travel-plans.effects';

export interface AppState {
  loader: LoaderState;
  usersPlansSummary: UsersPlansSummaryState;
  user:UserState;
  travelPlans: TravelPlansState;
  travelPlan: TravelPlanState;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  loader: loaderReducer,
  usersPlansSummary: usersPlansSummaryReducer,
  user:userReducer,
  travelPlans: travelPlansReducer,
  travelPlan: travelPlanReducer,
};

export const ROOT_EFFECTS = [UsersPlansSummaryEffects,UsersEffects,TravelPlansEffects];
