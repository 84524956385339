import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../services/auth/auth.service';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  switchMap,
  take,
} from 'rxjs';

const refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
  null
);

let isRefreshing = false;

export const authInterceptor: HttpInterceptorFn = (
  request: HttpRequest<any>,
  next: HttpHandlerFn
) => {
  if (request.url.includes(environment.uriBase)) {
    request = addTokenHeaderIfTokenExists(request);
  }

  const authService = inject(AuthService);

  return next(request).pipe(
    catchError((error) => {
      if (
        error instanceof HttpErrorResponse &&
        request.url.includes(environment.uriBase) &&
        !request.url.includes('Auth') &&
        error.status === 401
      ) {
        return handle401Error(request, next, authService);
      }

      throw error;
    })
  );
};

function handle401Error(
  request: HttpRequest<any>,
  next: HttpHandlerFn,
  authService: AuthService
): Observable<HttpEvent<any>> {
  if (!isRefreshing) {
    isRefreshing = true;
    refreshTokenSubject.next(null);

    const token = authService.getRefreshToken();

    if (token) {
      return authService.refreshToken(token).pipe(
        switchMap((authData) => {
          isRefreshing = false;

          authService.setAuthData({
            ...authService.getAuthData()!,
            idToken: authData.idToken,
            refreshToken: authData.refreshToken,
            expiresIn: authData.expiresIn,
          });

          refreshTokenSubject.next(authData.idToken);

          return next(addTokenHeader(request, authData.idToken));
        }),
        catchError((error) => {
          isRefreshing = false;
          authService.logout();

          throw error;
        })
      );
    }
  }

  return refreshTokenSubject.pipe(
    filter((token) => token !== null),
    take(1),
    switchMap((token) => next(addTokenHeader(request, token)))
  );
}

function addTokenHeaderIfTokenExists(
  request: HttpRequest<any>
): HttpRequest<unknown> {
  const authService = inject(AuthService);

  const token = authService.getToken();

  if (token) {
    return addTokenHeader(request, token);
  }

  return request;
}

function addTokenHeader(
  request: HttpRequest<any>,
  token: string
): HttpRequest<unknown> {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  });

  return request.clone({ headers });
}
