import { TransactionEntity } from '../entities/transaction.entity';
import { TransactionModel, TransactionType } from '../../../../domain/models/transaction.model';
import { Mapper } from '@base/mapper';

export class TransactionMapper extends Mapper<
  TransactionEntity,
  TransactionModel
> {
  override mapTo(param: TransactionModel): TransactionEntity {
    throw new Error('Method not implemented.');
  }
  override mapFrom(param: TransactionEntity): TransactionModel {
    return {
      ...param,
      type: TransactionType[param.type as keyof typeof TransactionType],

    };
  }


}
