import { Component } from '@angular/core';
import { LateralBarComponent } from './components/lateral-bar/lateral-bar.component';
import { FloatBarComponent } from './components/float-bar/float-bar.component';
import { UsersTableComponent } from './components/users-table/users-table.component';
import { RouterOutlet } from '@angular/router';
import { UsersDetailsComponent } from './components/users-details/users-details.component';

@Component({
  selector: 'app-modules',
  standalone: true,
  imports: [    LateralBarComponent,
    FloatBarComponent,
    UsersTableComponent,
    RouterOutlet,
    UsersDetailsComponent
    ],
  templateUrl: './modules.component.html',
  styleUrl: './modules.component.scss'
})
export class ModulesComponent {

}
