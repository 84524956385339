import { createReducer, on } from '@ngrx/store';
import * as travelPlanActions from '../actions/travel-plans.actions';
import { PaginatedData } from '@base/wrappers/paginated-data';
import { TravelPlanShortModel } from '@domain/models/travel-plan.model';

export interface TravelPlansState {
  pagination: PaginatedData<TravelPlanShortModel>;
  travelPlans: TravelPlanShortModel[];
  loading: boolean;
  error: any;
}

export const initialState: TravelPlansState = {
  pagination: {
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
    hasPrevious: false,
    hasNext: false,
  },
  travelPlans: [],
  loading: false,
  error: '',
};

export const travelPlansReducer = createReducer(
  initialState,
  on(travelPlanActions.loadTravelPlans, (state) => ({
    ...state,
    loading: true,
    travelPlans: []
  })),
  on(travelPlanActions.clearTravelPlans, (_) => ({
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      totalPages: 0,
      totalRecords: 0,
      hasPrevious: false,
      hasNext: false,
    },
    travelPlans: [],
    loading: false,
    error: '',
  })),
  on(travelPlanActions.loadTravelPlansSuccess, (state, { pagination }) => ({
    ...state,
    pagination: setPaginationProperties(pagination),
    travelPlans: [ ...(pagination.data ?? [])],
    loading: false,
    error: '',
  })),
  on(travelPlanActions.loadTravelPlansFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);

function setPaginationProperties(
  pagination: PaginatedData<TravelPlanShortModel>
): PaginatedData<TravelPlanShortModel> {
  return {
    ...pagination,
    data: pagination.data ?? [],
  };
}
