import { PaginatedData } from '@base/wrappers/paginated-data';
import { UseCase } from '@domain/base/use-case';
import { CatalogRepository } from '@domain/repositories/catalog.repository';
import { Observable } from 'rxjs';

export class GetCatalogContributionPricesUseCase
  implements UseCase<{ planId: string }, PaginatedData<number>>
{
  constructor(private catalogRepository: CatalogRepository) {}

  execute(): Observable<PaginatedData<number>> {
    return this.catalogRepository.getCatalogContributionPrices();
  }
}

