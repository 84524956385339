import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanContractRepository } from '@domain/repositories/plan-contract.repository';
import { GetPlansContractSummaryUseCase } from '@domain/usecases/plan-contract/get-plans-summary.usecase';
import { PlanContractImplRepository } from './plan-contract-impl.repository';

const getPlansContractSummaryUseCaseFactory = (
  planContractRepository: PlanContractRepository
) => new GetPlansContractSummaryUseCase(planContractRepository);

const getPlansContractSummaryUseCaseProvider = {
  provide: GetPlansContractSummaryUseCase,
  useFactory: getPlansContractSummaryUseCaseFactory,
  deps: [PlanContractRepository],
};

const planContractRepositoryProvider = {
  provide: PlanContractRepository,
  useClass: PlanContractImplRepository,
};

@NgModule({
  providers: [
    getPlansContractSummaryUseCaseProvider,
    planContractRepositoryProvider,
  ],
  imports: [CommonModule],
})
export class PlanContractModule {}
