import { UseCase } from '@domain/base/use-case';
import { TravelPlanSummaryModel } from '@domain/models/travel-plan-summary.model';
import { TravelPlanRepository } from '@domain/repositories/travel-plan.repository';
import { Observable } from 'rxjs';


export class GetPlanSummaryUseCase
  implements UseCase<{ planId: string }, TravelPlanSummaryModel>
{
  constructor(private travelPlanRepository: TravelPlanRepository) {}

  execute(params: { planId: string }): Observable<TravelPlanSummaryModel> {
    return this.travelPlanRepository.getPlanSummary(params.planId);
  }
}
