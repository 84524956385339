

import { TransactionEntity } from '../entities/transaction.entity';
import { TransactionType } from '../../../../domain/models/transaction.model';
import { ContributionEntity } from '../entities/contribution.entity';
import { Mapper } from '@base/mapper';
import { ContributionModel } from '@domain/models/contribution.model';

export class ContributionMapper extends Mapper<
  ContributionEntity,
  ContributionModel
> {
  override mapTo(param: ContributionModel): ContributionEntity {
    return {
      ...param,
    };
  }
  override mapFrom(param: ContributionEntity): ContributionModel {
    return {
      ...param,
    };
  }
}
