import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AdvanceAvailableMapper } from './mappers/advance-available-data.mapper';
import { PaginatedData } from '@base/wrappers/paginated-data';
import { ResponseData } from '@base/wrappers/response-data';
import { AdvancesAvailableModel } from '@domain/models/advance-available.model';
import { CatalogModel } from '@domain/models/catalog.model';
import { CatalogRepository } from '@domain/repositories/catalog.repository';
import { environment } from '@env/environment';
import { CatalogEntity } from './entities/catalog.model';
import { CatalogMapper } from './mappers/catalog-data.mapper';

@Injectable({
  providedIn: 'root',
})
export class CatalogImplRepository extends CatalogRepository {

  private readonly catalogUrl: string;
  private catalogMapper = new CatalogMapper();
  private advanceAvailableMapper = new AdvanceAvailableMapper();

  constructor(private http: HttpClient) {
    super();
    this.catalogUrl = `${environment.uriBase}Catalog`;
  }

  override getCatalogDocumentTypes(
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<CatalogModel>> {
    const params = new HttpParams();

    params.set('pageNumber', pageNumber.toString());
    params.set('pageSize', pageSize.toString());

    return this.http
      .get<ResponseData<PaginatedData<CatalogEntity>>>(
        `${this.catalogUrl}/document-types`,
        { params }
      )
      .pipe(
        map((result) => ({
          ...result.data,
          data: (result.data.data ?? []).map(this.catalogMapper.mapFrom),
        }))
      );
  }

  override getCatalogContributionPrices(): Observable<PaginatedData<number>> {
    return this.http
    .get<ResponseData<PaginatedData<number>>>(
      `${this.catalogUrl}/contribution-prices`,
    )
    .pipe(
      map((result) => ({
        ...result.data,
        data: (result.data.data ?? []).map(response => response),
      }))
    );
  }

  override getCataloAdvancesAvailable(pageNumber: number, pageSize: number, savingsPlanId: string): Observable<PaginatedData<AdvancesAvailableModel>> {
    const params = new HttpParams();

    params.set('pageNumber', pageNumber.toString());
    params.set('pageSize', pageSize.toString());

    return this.http
    .get<ResponseData<PaginatedData<AdvancesAvailableModel>>>(
      `${this.catalogUrl}/advances-available/savings-plan/${savingsPlanId}`,
      { params }
    )
    .pipe(
      map((result) => ({
        ...result.data,
        data: (result.data.data ?? []).map(this.advanceAvailableMapper.mapFrom),
      }))
    );
  }

}
