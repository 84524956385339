import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { UseCase } from '@domain/base/use-case';
import { TravelPlanSummaryModel } from '@domain/models/travel-plan-summary.model';
import { TravelPlanState } from '@domain/models/travel-plan.model';
import { TravelPlanRepository } from '@domain/repositories/travel-plan.repository';
import { PaginatedData } from '@base/wrappers/paginated-data';

export class GetTravelPlansSummaryUseCase
  implements
    UseCase<
      {
        userId:string;
        state: TravelPlanState;
        pageNumber?: number;
        pageSize?: number;
      },
      PaginatedData<TravelPlanSummaryModel>
    >
{
  constructor(private travelPlanRepository: TravelPlanRepository) {}

  execute(params: {
    userId:string;
    state: TravelPlanState;
    pageNumber?: number;
    pageSize?: number;
  }): Observable<PaginatedData<TravelPlanSummaryModel>> {
    return this.travelPlanRepository.getPlansSummary(
      params.userId,
      params.state,
      params.pageNumber ?? 1,
      params.pageSize ?? 100
    );
  }
}
