import { Mapper } from '../../../../base/mapper';
import { UserPlansSummaryModel } from '../../../../domain/models/user.model';
import { UserPlansSummaryEntity } from '../entities/user-entity';

export class UserPlansSummaryMapper extends Mapper<
  UserPlansSummaryEntity,
  UserPlansSummaryModel
> {
  override mapFrom(param: UserPlansSummaryEntity): UserPlansSummaryModel {
    return { ...param };
  }

  override mapTo(param: UserPlansSummaryModel): UserPlansSummaryEntity {
    throw new Error('Method not implemented.');
  }
}
