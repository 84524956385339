import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { PlanContractSummaryMapper } from './mappers/plan-contract-summary.mapper';
import { TravelPlanState } from '@domain/models/travel-plan.model';
import { PaginatedData } from '@base/wrappers/paginated-data';
import { PlanContractSummaryModel } from '@domain/models/plan-contract-summary.model';
import { ResponseData } from '@base/wrappers/response-data';
import { PlanContractSummaryEntity } from './entities/plan-contract-summary.entity';
import { PlanContractRepository } from '@domain/repositories/plan-contract.repository';

@Injectable({
  providedIn: 'root',
})
export class PlanContractImplRepository extends PlanContractRepository {
  private readonly planContractUrl: string;

  private planContractMapper = new PlanContractSummaryMapper();

  constructor(private http: HttpClient) {
    super();
    this.planContractUrl = `${environment.uriBase}PlanContract`;
  }

  getPlansSummary(
    userId: string,
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<PlanContractSummaryModel>> {
    let params = new HttpParams();

    params = params.set('State', state.toString());
    params = params.set('PageNumber', pageNumber.toString());
    params = params.set('PageSize', pageSize.toString());

    return this.http
      .get<ResponseData<PaginatedData<PlanContractSummaryEntity>>>(
        `${this.planContractUrl}/summary/user/${userId}`,
        { params }
      )
      .pipe(
        map((result) => ({
          ...result.data,
          data: (result.data.data ?? []).map(this.planContractMapper.mapFrom),
        }))
      );
  }
}
