import { PaginatedData } from '@base/wrappers/paginated-data';
import { UseCase } from '@domain/base/use-case';
import { CatalogModel } from '@domain/models/catalog.model';
import { CatalogRepository } from '@domain/repositories/catalog.repository';
import { Observable } from 'rxjs';
export class GetCatalogDocumentTypesUseCase
  implements UseCase<{pageNumber?: number; pageSize?: number; }, PaginatedData<CatalogModel>>
{
  constructor(private catalogRepository: CatalogRepository) {}

  execute(
    params : {pageNumber?: number, pageSize?: number, }
  ): Observable<PaginatedData<CatalogModel>> {
    return this.catalogRepository.getCatalogDocumentTypes(params.pageNumber ?? 1,
      params.pageSize ?? 100);
  }
}
