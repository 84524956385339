import { Observable } from 'rxjs';
import { PaginatedData } from '@base/wrappers/paginated-data';
import { UseCase } from '@domain/base/use-case';
import {
  UserPlansSummaryFilters,
  UserPlansSummaryModel,
} from '@domain/models/user.model';
import { UserRepository } from '@domain/repositories/user.repository';

export class GetUsersPlansSummaryUseCase
  implements
    UseCase<UserPlansSummaryFilters, PaginatedData<UserPlansSummaryModel>>
{
  constructor(private userRepository: UserRepository) {}

  execute(
    filters: UserPlansSummaryFilters
  ): Observable<PaginatedData<UserPlansSummaryModel>> {
    return this.userRepository.getUsersPlansSummary(filters);
  }
}
