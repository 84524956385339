import { PaginatedData } from "@base/wrappers/paginated-data";
import { UserModel } from "@domain/models/user.model";
import { createReducer, on } from "@ngrx/store";
import { UserActions } from "../actions/user.actions";

export interface UserState {
  user?: UserModel;
  loading: boolean;
  error: any;
}

export const initialState: UserState = {

  loading: false,
  error: '',
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.loadUser, (state) => ({
    ...state,
    loading: true,
  })),

  on(
    UserActions.loadUserSuccess,
    (state, { user }) => ({
      ...state,
      user: user,
      loading: state.loading,
    })
  ),
  on(UserActions.loadUserFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
