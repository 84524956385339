import { Mapper } from "@base/mapper";
import { AdvancesAvailableModel } from "@domain/models/advance-available.model";
import { AdvancesAvailableEntity } from "../entities/advance-available.entity";




export class AdvanceAvailableMapper extends Mapper<
  AdvancesAvailableEntity,
  AdvancesAvailableModel
> {
  override mapTo(param: AdvancesAvailableModel): AdvancesAvailableEntity {
    return {
      ...param,
    };
  }
  override mapFrom(param: AdvancesAvailableEntity): AdvancesAvailableModel {
    return {
      ...param,
    };
  }
}
