import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { hideLoader, showLoader } from '../actions/loader.actions';
import { UserActions } from '../actions/user.actions';
import { GetUserUseCase } from '@domain/usecases/user/get-user.usecase';
import { AlertsService } from '@shared/services/alerts/alerts.service';

@Injectable()
export class UsersEffects {

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUser),
      mergeMap((params) => {
        this.store.dispatch(showLoader());

        return this.getUserUseCase.execute({idUser:params.idUser}).pipe(
          mergeMap((user) =>
            of(
              UserActions.loadUserSuccess({
                user:user,
                loading:false,
              })
            )
          ),
          catchError((error) => {
            return of(UserActions.loadUserFailure({ error,loading:false }));
          })
        );
      })
    )
  );


  handleLoadUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserSuccess),
      mergeMap((action) => {
        return of(hideLoader());
      })
    )
  );

  handleLoadUsersPlansSummaryFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserFailure),
      mergeMap((action) => {

        this.alertService.showAlertError(
          'Ocurrió un error al cargar los usuarios. Por favor, intenta nuevamente.'
        );

        console.error('Error: ', action.error);

        return of(hideLoader());
      })
    )
  );


  constructor(
    private store: Store,
    private actions$: Actions,
    private getUserUseCase: GetUserUseCase,
    private alertService: AlertsService
  ) {}
}
