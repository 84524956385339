import { Routes } from '@angular/router';
import { UsersComponent } from './pages/modules/users/users.component';
import { authGuard } from '@shared/guards/auth.guard';
import { ModulesComponent } from './pages/modules/modules.component';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import(`./pages/auth/routes`),
  },
  {
    path: '',
    loadChildren: () => import(`./pages/modules/routes`),
    component: ModulesComponent,
    canMatch: [authGuard],
  },

  // {
  //   path: '',
  //   redirectTo: 'users',
  //   pathMatch: 'full',
  // },
];
