import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { GetUsersPlansSummaryUseCase } from '@domain/usecases/user/get-users-plans-summary.usecase';
import { AlertsService } from '@shared/services/alerts/alerts.service';
import { UsersPlansSummaryActions } from '../actions/users-plans-summary.actions';
import { hideLoader, showLoader } from '../actions/loader.actions';

@Injectable()
export class UsersPlansSummaryEffects {
  loadUsersPlansSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersPlansSummaryActions.loadUsers),
      mergeMap((params) => {
        this.store.dispatch(showLoader());

        return this.getUsersPlansSummaryUseCase.execute(params.filters).pipe(
          mergeMap((paginatedUsers) =>
            of(
              UsersPlansSummaryActions.loadUsersSuccess({
                pagination: paginatedUsers,
                filters: params.filters,
              })
            )
          ),
          catchError((error) => {
            return of(UsersPlansSummaryActions.loadUsersFailure({ error }));
          })
        );
      })
    )
  );

  handleLoadUsersPlansSummarySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersPlansSummaryActions.loadUsersSuccess),
      mergeMap((action) => {
        return of(hideLoader());
      })
    )
  );

  handleLoadUsersPlansSummaryFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersPlansSummaryActions.loadUsersFailure),
      mergeMap((action) => {
        this.store.dispatch(hideLoader());

        this.alertService.showAlertError(
          'Ocurrió un error al cargar los usuarios. Por favor, intenta nuevamente.'
        );

        console.error('Error: ', action.error);

        return of(UsersPlansSummaryActions.clearUsers());
      })
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private getUsersPlansSummaryUseCase: GetUsersPlansSummaryUseCase,
    private alertService: AlertsService
  ) {}
}
