import { UseCase } from '@domain/base/use-case';
import { TravelPlanModel } from '@domain/models/travel-plan.model';
import { TravelPlanRepository } from '@domain/repositories/travel-plan.repository';
import { Observable } from 'rxjs';

export class PatchEditPlanUseCase
  implements
    UseCase<
      {
        planId: string;
        numberContributions: number;
        monthlyContributionPrice: number;
      },
      TravelPlanModel
    >
{
  constructor(private travelPlanRepository: TravelPlanRepository) {}

  execute(params: {
    planId: string;
    numberContributions: number;
    monthlyContributionPrice: number;
  }): Observable<TravelPlanModel> {
    return this.travelPlanRepository.patchEditPlan(
      params.planId,
      params.numberContributions,
      params.monthlyContributionPrice
    );
  }
}
