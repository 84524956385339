
export enum TransactionType{
  Contribution='Contribution'

}


export interface TransactionModel {
  type:      TransactionType;
  amount:    number;
  confirmed: boolean;
  createdAt: Date;
}
