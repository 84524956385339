import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { UserRepository } from '@domain/repositories/user.repository';
import { PaginatedData } from '@base/wrappers/paginated-data';
import {
  UserModel,
  UserPlansSummaryFilters,
  UserPlansSummaryModel,
} from '@domain/models/user.model';
import { UserPlansSummaryMapper } from './mappers/user-plans-summary.mapper';
import { environment } from '@env/environment';
import { ResponseData } from '@base/wrappers/response-data';
import { UserEntity, UserPlansSummaryEntity } from './entities/user-entity';
import { UserMapper } from './mappers/user.mapper';

@Injectable({
  providedIn: 'root',
})
export class UserImplRepository extends UserRepository {
  private readonly userUrl: string;
  private userMapper = new UserMapper();
  private userPlansSummaryMapper = new UserPlansSummaryMapper();

  constructor(private http: HttpClient) {
    super();
    this.userUrl = `${environment.uriBase}User`;
  }

  override getUser(idUser: string): Observable<UserModel> {
    return this.http
      .get<ResponseData<UserEntity>>(`${this.userUrl}/${idUser}`)
      .pipe(
        map((result) => {
          return this.userMapper.mapFrom(result.data);
        })
      );
  }

  override getUsersPlansSummary(
    filters: UserPlansSummaryFilters
  ): Observable<PaginatedData<UserPlansSummaryModel>> {
    let params = new HttpParams();

    Object.keys(filters).forEach((key) => {
      let value = filters[key as keyof UserPlansSummaryFilters];

      if (value !== null && value !== undefined) {
        if (key === 'sortBy') {
          value = this.capitalize(value.toString());
        }

        params = params.append(key, value);
      }
    });

    params = params.append('Role', 'Customer');

    return this.http
      .get<ResponseData<PaginatedData<UserPlansSummaryEntity>>>(
        `${this.userUrl}/`,
        { params }
      )
      .pipe(
        map((result) => ({
          ...result.data,
          data: (result.data.data ?? []).map(
            this.userPlansSummaryMapper.mapFrom
          ),
        }))
      );
  }
  override postChangePassword(
    idUser: string,
    currentPassword: string,
    newPassword: string
  ): Observable<boolean> {
    console.log({ idUser });

    return this.http
      .post<ResponseData<boolean>>(
        `${this.userUrl}/${idUser}/change-password`,
        {
          currentPassword: currentPassword,
          newPassword: newPassword,
        }
      )
      .pipe(
        map((result) => {
          return true;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.error.message === 'Current password is invalid') {
            return throwError(() => 'La contraseña actual no es válida');
          }
          return throwError(() => error.error.message);
        })
      );
  }

  private capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
