import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRepository } from '../../../domain/repositories/user.repository';
import { UserImplRepository } from './user-impl.repository';
import { GetUsersPlansSummaryUseCase } from '@domain/usecases/user/get-users-plans-summary.usecase';
import { GetUserUseCase } from '../../../domain/usecases/user/get-user.usecase';
import { PostChangePasswordUseCase } from '@domain/usecases/user/post-change-password.usecase';

const getUsersPlansSummaryUseCaseFactory = (userRepository: UserRepository) =>
  new GetUsersPlansSummaryUseCase(userRepository);

const getUsersPlansSummaryUseCaseProvider = {
  provide: GetUsersPlansSummaryUseCase,
  useFactory: getUsersPlansSummaryUseCaseFactory,
  deps: [UserRepository],
};

const userRepositoryProvider = {
  provide: UserRepository,
  useClass: UserImplRepository,
};

const getUserUseCaseFactory = (
  userRepository: UserRepository
) => new GetUserUseCase(userRepository);

const getUserUseCaseProvider = {
  provide: GetUserUseCase,
  useFactory: getUserUseCaseFactory,
  deps: [UserRepository],
};

const postChangePasswordUseCaseFactory = (
  userRepository: UserRepository
) => new PostChangePasswordUseCase(userRepository);

const postChangePasswordUseCaseProvider = {
  provide: PostChangePasswordUseCase,
  useFactory: postChangePasswordUseCaseFactory,
  deps: [UserRepository],
};


@NgModule({
  providers: [getUsersPlansSummaryUseCaseProvider, userRepositoryProvider, getUserUseCaseProvider,postChangePasswordUseCaseProvider],
  imports: [CommonModule],
})
export class UserModule {}
