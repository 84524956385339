import { Observable } from 'rxjs';
import { PaginatedData } from '@base/wrappers/paginated-data';
import { TravelPlanState } from '@domain/models/travel-plan.model';
import { PlanContractSummaryModel } from '@domain/models/plan-contract-summary.model';

export abstract class PlanContractRepository {
  abstract getPlansSummary(
    userId: string,
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<PlanContractSummaryModel>>;
}
