
import { Mapper } from '@base/mapper';
import { TravelPlanShortModel, TravelPlanState } from '@domain/models/travel-plan.model';
import { TravelPlanEntity } from '../entities/travel-plan-entity';


export class ShortTravelPlanMapper extends Mapper<
  TravelPlanEntity,
  TravelPlanShortModel
> {
  override mapFrom(param: TravelPlanEntity): TravelPlanShortModel {
    return {
      ...param,
      state: TravelPlanState[param.state as keyof typeof TravelPlanState],
    };
  }

  override mapTo(param: TravelPlanShortModel): TravelPlanEntity {
    throw new Error('Method not implemented.');
  }
}
