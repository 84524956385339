import { Mapper } from '../../../../base/mapper';
import { UserModel } from '../../../../domain/models/user.model';
import { UserEntity } from '../entities/user-entity';

export class UserMapper extends Mapper<UserEntity, UserModel> {
  override mapFrom(param: UserEntity): UserModel {
    return { ...param };
  }

  override mapTo(param: UserModel): UserEntity {
    throw new Error('Method not implemented.');
  }
}
