import { Component, Input } from '@angular/core';
import { AlertsService } from '../../services/alerts/alerts.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'shared-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
  standalone : true,
  imports: [CommonModule]
})
export class AlertComponent {

  public alertState = { show: false, text: '', backgroundColor: '',icon: '' };

  constructor(private alertService: AlertsService) {

    this.alertService.alertState$.subscribe((state) => {
      this.alertState = state;
    });
  }

  closeAlert() {
    this.alertService.hideAlert();
  }
}
