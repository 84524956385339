import { createReducer, on } from '@ngrx/store';
import * as travelPlanActions from '../actions/travel-plan.actions';
import { PaymentSummaryModel } from '@domain/models/payment-summary.model';
import { TravelPlanModel } from '@domain/models/travel-plan.model';
import { SavingsPlanDataModel } from '@domain/savings-plan/models/savings-plan-data.model';


export interface TravelPlanState {
  data: TravelPlanModel | null;
  paymentData: PaymentSummaryModel | null;
  subscriptionInfo: SavingsPlanDataModel | null;
}

export const initialState: TravelPlanState = {
  data: null,
  paymentData: null,
  subscriptionInfo: null,
};

export const travelPlanReducer = createReducer(
  initialState,
  on(travelPlanActions.removeTravelPlan, (_) => ({
    data: null,
    paymentData: null,
    subscriptionInfo: null,
  })),
  on(travelPlanActions.setTravelPlan, (state, { travelPlan }) => ({
    ...state,
    data: travelPlan,
  })),
  on(travelPlanActions.setPaymentTravelPlan, (state, { paymentPlan }) => ({
    ...state,
    paymentData: paymentPlan,
  })),
  on(
    travelPlanActions.setTravelPlanSubscriptionInfo,
    (state, { subscriptionInfo }) => ({
      ...state,
      subscriptionInfo,
    })
  ),
  on(travelPlanActions.clearTravelPlanSubscriptionInfo, (state) => ({
    ...state,
    subscriptionInfo: null,
  }))
);
