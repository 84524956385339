import { UseCase } from '@domain/base/use-case';
import { ContributionModel } from '@domain/models/contribution.model';
import { TravelPlanRepository } from '@domain/repositories/travel-plan.repository';
import { Observable } from 'rxjs';


export class PostTransactionContributionUseCase
  implements UseCase<{ contribution: ContributionModel ,planId:string}, ContributionModel>
{
  constructor(private travelPlanRepository: TravelPlanRepository) {}

  execute(params: { contribution: ContributionModel ,planId:string}): Observable<ContributionModel> {
    return this.travelPlanRepository.postTransactionContribution(params.contribution,params.planId);
  }
}
