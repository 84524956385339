import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TravelPlanRepository } from '@domain/repositories/travel-plan.repository';
import { DeletePlanUseCase } from '@domain/usecases/travel-plan/delete-plan.usecase';
import { GetPaymentSummaryUseCase } from '@domain/usecases/travel-plan/get-payment-summary.usecase';
import { GetPlanSummaryUseCase } from '@domain/usecases/travel-plan/get-plan-summary.usecase';
import { GetTravelPlanUseCase } from '@domain/usecases/travel-plan/get-plan.usecase';
import { GetTravelPlansSummaryUseCase } from '@domain/usecases/travel-plan/get-plans-summary.usecase';
import { GetTravelPlansUseCase } from '@domain/usecases/travel-plan/get-plans.usecase';
import { GetTransationsListUseCase } from '@domain/usecases/travel-plan/get-transactions-list.usecase';
import { PatchEditPlanUseCase } from '@domain/usecases/travel-plan/patch-edit-plan.usecase';
import { PostTransactionContributionUseCase } from '@domain/usecases/travel-plan/post-transaction-contribution.usecase';
import { TravelPlanImplRepository } from './travel-plan-impl.repository';

const getTravelPlansUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new GetTravelPlansUseCase(travelPlanRepository);

const getTravelPlansUseCaseProvider = {
  provide: GetTravelPlansUseCase,
  useFactory: getTravelPlansUseCaseFactory,
  deps: [TravelPlanRepository],
};

const getPaymentSummaryUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new GetPaymentSummaryUseCase(travelPlanRepository);

const getPaymentSummaryUseCaseProvider = {
  provide: GetPaymentSummaryUseCase,
  useFactory: getPaymentSummaryUseCaseFactory,
  deps: [TravelPlanRepository],
};

const getTravelPlanUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new GetTravelPlanUseCase(travelPlanRepository);

const getTravelPlanUseCaseProvider = {
  provide: GetTravelPlanUseCase,
  useFactory: getTravelPlanUseCaseFactory,
  deps: [TravelPlanRepository],
};


  const getTravelPlansSummaryUseCaseFactory = (
    travelPlanRepository: TravelPlanRepository
  ) => new GetTravelPlansSummaryUseCase(travelPlanRepository);

const getTravelPlansSummaryUseCaseProvider = {
  provide: GetTravelPlansSummaryUseCase,
  useFactory: getTravelPlansSummaryUseCaseFactory,
  deps: [TravelPlanRepository],
};

const getTransactionListUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new GetTransationsListUseCase(travelPlanRepository);

const getTransactionListUseCaseProvider = {
  provide: GetTransationsListUseCase,
  useFactory: getTransactionListUseCaseFactory,
  deps: [TravelPlanRepository],
};

const getPlanSummaryUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new GetPlanSummaryUseCase(travelPlanRepository);

const getTravelPlanSummaryUseCaseProvider = {
  provide: GetPlanSummaryUseCase,
  useFactory: getPlanSummaryUseCaseFactory,
  deps: [TravelPlanRepository],
};


const postTransactionContributionUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new PostTransactionContributionUseCase(travelPlanRepository);

const postTransactionContributionUseCaseProvider = {
  provide: PostTransactionContributionUseCase,
  useFactory: postTransactionContributionUseCaseFactory,
  deps: [TravelPlanRepository],
};
const deletePlanUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new DeletePlanUseCase(travelPlanRepository);

const deletePlansUseCaseProvider = {
  provide: DeletePlanUseCase,
  useFactory: deletePlanUseCaseFactory,
  deps: [TravelPlanRepository],
};


const patchEditPlanUseCaseFactory = (
  travelPlanRepository: TravelPlanRepository
) => new PatchEditPlanUseCase(travelPlanRepository);

const patchEditPlanUseCaseProvider = {
  provide: PatchEditPlanUseCase,
  useFactory: patchEditPlanUseCaseFactory,
  deps: [TravelPlanRepository],
};


const travelPlanRepositoryProvider = {
  provide: TravelPlanRepository,
  useClass: TravelPlanImplRepository,
};

@NgModule({
  providers: [
    getTravelPlansUseCaseProvider,
    getTravelPlanUseCaseProvider,
    getPaymentSummaryUseCaseProvider,
    getTravelPlansSummaryUseCaseProvider,
    getTransactionListUseCaseProvider,
    getTravelPlanSummaryUseCaseProvider,
    postTransactionContributionUseCaseProvider,
    deletePlansUseCaseProvider,
    patchEditPlanUseCaseProvider,
    travelPlanRepositoryProvider,
  ],
  imports: [CommonModule],
})
export class TravelPlanModule {}
