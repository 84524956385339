import {
  ApplicationConfig,
  LOCALE_ID,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { registerLocaleData } from '@angular/common';
import localeEsCO from '@angular/common/locales/es-CO';

import { routes } from './app.routes';
import { DataModule } from '../../data/data.module';
import { ROOT_EFFECTS, ROOT_REDUCERS } from '@core/store/app.state';
import { authInterceptor } from '@shared/interceptors/auth/auth.interceptor';

registerLocaleData(localeEsCO);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withFetch(), withInterceptors([authInterceptor])),
    { provide: LOCALE_ID, useValue: 'es-CO' },
    provideStore(ROOT_REDUCERS),
    provideEffects(ROOT_EFFECTS),
    importProvidersFrom(DataModule),
  ],
};
