import { Mapper } from '@base/mapper';
import { PlanContractSummaryEntity } from '../entities/plan-contract-summary.entity';
import { PlanContractSummaryModel } from '@domain/models/plan-contract-summary.model';

export class PlanContractSummaryMapper extends Mapper<
  PlanContractSummaryEntity,
  PlanContractSummaryModel
> {
  override mapFrom(param: PlanContractSummaryEntity): PlanContractSummaryModel {
    return { ...param };
  }

  override mapTo(param: PlanContractSummaryModel): PlanContractSummaryEntity {
    throw new Error('Method not implemented.');
  }
}
