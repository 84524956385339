import { Observable } from 'rxjs';
import { PaginatedData } from '@base/wrappers/paginated-data';
import {
  UserModel,
  UserPlansSummaryFilters,
  UserPlansSummaryModel,
} from '@domain/models/user.model';

export abstract class UserRepository {
  abstract getUsersPlansSummary(params: UserPlansSummaryFilters): Observable<PaginatedData<UserPlansSummaryModel>>;

  abstract getUser(idUser: string): Observable<UserModel>;

  abstract postChangePassword(idUser:string,currentPassword:string,newPassword:string): Observable<boolean>;


}
