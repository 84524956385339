import { createReducer, on } from '@ngrx/store';
import { PaginatedData } from '@base/wrappers/paginated-data';
import {
  UserPlansSummaryFilters,
  UserPlansSummaryModel,
} from '@domain/models/user.model';
import { UsersPlansSummaryActions } from '../actions/users-plans-summary.actions';

export interface UsersPlansSummaryState {
  pagination: PaginatedData<UserPlansSummaryModel>;
  users: UserPlansSummaryModel[];
  filters: UserPlansSummaryFilters;
  loading: boolean;
  error: any;
}

export const initialState: UsersPlansSummaryState = {
  pagination: {
    pageNumber: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
    hasPrevious: false,
    hasNext: false,
  },
  users: [],
  filters: {},
  loading: false,
  error: '',
};

export const usersPlansSummaryReducer = createReducer(
  initialState,
  on(UsersPlansSummaryActions.loadUsers, (state) => ({
    ...state,
    loading: true,
  })),
  on(UsersPlansSummaryActions.clearUsers, (_) => ({
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      totalPages: 0,
      totalRecords: 0,
      hasPrevious: false,
      hasNext: false,
    },
    users: [],
    filters: {},
    loading: false,
    error: '',
  })),
  on(
    UsersPlansSummaryActions.loadUsersSuccess,
    (state, { pagination, filters }) => ({
      ...state,
      pagination: setPaginationProperties(pagination),
      users: pagination.data ?? [],
      filters,
      loading: false,
      error: '',
    })
  ),
  on(UsersPlansSummaryActions.loadUsersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);

function setPaginationProperties(
  pagination: PaginatedData<UserPlansSummaryModel>
): PaginatedData<UserPlansSummaryModel> {
  return {
    ...pagination,
    data: pagination.data ?? [],
  };
}
