import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatalogRepository } from '@domain/repositories/catalog.repository';
import { GetCatalogAdvancesAvailableUseCase } from '@domain/usecases/catalog/get-catalog-advances-available.usecase';
import { GetCatalogContributionPricesUseCase } from '@domain/usecases/catalog/get-catalog-contribution-prices-list.usecase';
import { GetCatalogDocumentTypesUseCase } from '@domain/usecases/catalog/get-catalog-document-types.usecase';
import { CatalogImplRepository } from './catalog-impl.repository';

const getCatalogDocumentTypesUseCaseFactory = (
  catalogRepository: CatalogRepository
) => new GetCatalogDocumentTypesUseCase(catalogRepository);

const getCatalogDocumentTypesUseCaseProvider = {
  provide: GetCatalogDocumentTypesUseCase,
  useFactory: getCatalogDocumentTypesUseCaseFactory,
  deps: [CatalogRepository],
};


const getCatalogContributionPricesUseCaseFactory = (
  catalogRepository: CatalogRepository
) => new GetCatalogContributionPricesUseCase(catalogRepository);

const getCatalogContributionPricesUseCaseProvider = {
  provide: GetCatalogContributionPricesUseCase,
  useFactory: getCatalogContributionPricesUseCaseFactory,
  deps: [CatalogRepository],
};


const getCatalogAdvanceAvailableUseCaseFactory = (
  catalogRepository: CatalogRepository
) => new GetCatalogAdvancesAvailableUseCase(catalogRepository);

const getCatalogAdvanceAvailableUseCaseProvider = {
  provide: GetCatalogAdvancesAvailableUseCase,
  useFactory: getCatalogAdvanceAvailableUseCaseFactory,
  deps: [CatalogRepository],
};


const catalogRepositoryProvider = {
  provide: CatalogRepository,
  useClass: CatalogImplRepository,
};

@NgModule({
  providers: [
    getCatalogDocumentTypesUseCaseProvider,
    getCatalogContributionPricesUseCaseProvider,
    getCatalogAdvanceAvailableUseCaseProvider,
    catalogRepositoryProvider,
  ],
  imports: [CommonModule],
})
export class CatalogModule {}
