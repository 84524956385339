import { PaginatedData } from '@base/wrappers/paginated-data';
import { UseCase } from '@domain/base/use-case';
import { TransactionModel } from '@domain/models/transaction.model';
import { TravelPlanRepository } from '@domain/repositories/travel-plan.repository';
import { Observable } from 'rxjs';

export class GetTransationsListUseCase
  implements UseCase<{ planId: string }, PaginatedData<TransactionModel>>
{
  constructor(private travelPlanRepository: TravelPlanRepository) {}

  execute(params: { planId: string }): Observable<PaginatedData<TransactionModel>> {
    return this.travelPlanRepository.getTransactionsList(params.planId);
  }
}
