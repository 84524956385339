import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError } from 'rxjs/operators';
import * as travelPlanActions from '../actions/travel-plans.actions';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { AlertsService } from '@shared/services/alerts/alerts.service';
import { GetTravelPlansUseCase } from '@domain/usecases/travel-plan/get-plans.usecase';

@Injectable()
export class TravelPlansEffects {
  loadTravelPlans$ = createEffect(() =>
    this.actions$.pipe(

      ofType(travelPlanActions.loadTravelPlans),
      mergeMap((params) =>
        this.getTravelPlansUseCase.execute(params).pipe(
          mergeMap((paginatedTravelPlans) =>
            of(
              travelPlanActions.loadTravelPlansSuccess({
                pagination: paginatedTravelPlans,
              })
            ).pipe(
              catchError((error) => {
                this.store.dispatch(
                  travelPlanActions.loadTravelPlansFailure({ error })
                );

                return of(travelPlanActions.loadTravelPlansFailure({ error }));
              })
            )
          )
        )
      )
    )
  );

  handleLoadTravelPlansFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(travelPlanActions.loadTravelPlansFailure),
      mergeMap((action) => {
        this.alertService.showAlertError(
          'Ocurrió un error al cargar los planes'
        );
        console.error('Error: ', action.error);

        return of(travelPlanActions.clearTravelPlans());
      })
    )
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private getTravelPlansUseCase: GetTravelPlansUseCase,
    private alertService: AlertsService
  ) {}
}
