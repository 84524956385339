import { Observable } from 'rxjs';
import {
  TravelPlanModel,
  TravelPlanShortModel,
  TravelPlanState,
  TravelPlanSummaryModel,
} from '../models/travel-plan.model';
import { PaginatedData } from '../../base/wrappers/paginated-data';
import { TransactionModel } from '../models/transaction.model';
import { ContributionModel } from '../models/contribution.model';
import { PaymentSummaryEntity } from '@data/repositories/travel-plan/entities/payment-summary.entity';

export abstract class TravelPlanRepository {
  abstract getPlan(id: string): Observable<TravelPlanModel>;

  abstract getPlans(
    userId:string,
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<TravelPlanShortModel>>;

  abstract getPlansSummary(
    userId:string,
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<TravelPlanSummaryModel>>;

  abstract getPaymentSummary(planId: string): Observable<PaymentSummaryEntity>;

  abstract getTransactionsList(
    planId: string
  ): Observable<PaginatedData<TransactionModel>>;

  abstract getPlanSummary(planId: string): Observable<TravelPlanSummaryModel>;

  abstract postTransactionContribution(contribution: ContributionModel,planId:string): Observable<ContributionModel>;

  abstract deletePlan(id: string): Observable<{}>;

  abstract patchEditPlan(  planId: string,
    numberContributions: number,
    monthlyContributionPrice: number): Observable<TravelPlanModel>;

}
