import { NgModule, importProvidersFrom } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserModule } from './repositories/user/user.module';
import { AuthModule } from './repositories/auth/auth.module';
import { TravelPlanModule } from './repositories/travel-plan/travel-plan.module';
import { CatalogModule } from './repositories/catalog/catalog.module';
import { PlanContractModule } from './repositories/plan-contract/plan-contract.module';

@NgModule({
  providers: [
    importProvidersFrom(
      UserModule,
      AuthModule,
      TravelPlanModule,
      CatalogModule,
      PlanContractModule
    ),
  ],
  imports: [CommonModule],
})
export class DataModule {}
