<div class="w-100 vh-100 d-flex">
  <section class="sidebar-section position-sticky top-0 bottom-0">
    <app-lateral-bar></app-lateral-bar>
  </section>
  <div class="px-4 h-100 content-section">


      <router-outlet></router-outlet>

  </div>
</div>
