import { PaymentSummaryEntity } from '@data/repositories/travel-plan/entities/payment-summary.entity';
import { UseCase } from '@domain/base/use-case';
import { TravelPlanRepository } from '@domain/repositories/travel-plan.repository';
import { Observable } from 'rxjs';

export class GetPaymentSummaryUseCase
  implements UseCase<{ planId: string }, PaymentSummaryEntity>
{
  constructor(private travelPlanRepository: TravelPlanRepository) {}

  execute(params: { planId: string }): Observable<PaymentSummaryEntity> {
    return this.travelPlanRepository.getPaymentSummary(params.planId);
  }
}
