import { Mapper } from "@base/mapper";
import { CatalogEntity } from "../entities/catalog.model";
import { CatalogModel } from "@domain/models/catalog.model";


export class CatalogMapper extends Mapper<
  CatalogEntity,
  CatalogModel
> {
  override mapTo(param: CatalogModel): CatalogEntity {
    return {
      ...param,
    };
  }
  override mapFrom(param: CatalogEntity): CatalogModel {
    return {
      ...param,
    };
  }
}
