import { UseCase } from '@domain/base/use-case';
import { TravelPlanRepository } from '@domain/repositories/travel-plan.repository';
import { Observable } from 'rxjs';


export class DeletePlanUseCase
  implements UseCase<{ id: string }, {}>
{
  constructor(private travelPlanRepository: TravelPlanRepository) {}

  execute(params: { id: string }): Observable<{}> {
    return this.travelPlanRepository.deletePlan(params.id);
  }
}
