import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRepository } from '@domain/repositories/auth.repository';
import { AuthImplRepository } from './auth-impl.repository';
import { LoginUseCase } from '@domain/usecases/auth/login.usecase';
import { RefreshTokenUseCase } from '@domain/usecases/auth/refresh-token.usecase';
import { RecoveryUseCase } from '@domain/usecases/auth/recovery.usecase';

const refreshTokenUseCaseFactory = (
  authRepository: AuthRepository
) => new RefreshTokenUseCase(authRepository);

const refreshTokenUseCaseProvider = {
  provide: RefreshTokenUseCase,
  useFactory: refreshTokenUseCaseFactory,
  deps: [AuthRepository],
};
const loginUseCaseFactory = (
  authRepository: AuthRepository
) => new LoginUseCase(authRepository);

const loginUseCaseProvider = {
  provide: LoginUseCase,
  useFactory: loginUseCaseFactory,
  deps: [AuthRepository],
};

const recoveyUseCaseFactory = (
  authRepository: AuthRepository
) => new RecoveryUseCase(authRepository);

const recoveyUseCaseProvider = {
  provide: RecoveryUseCase,
  useFactory: recoveyUseCaseFactory,
  deps: [AuthRepository],
};

const userRepositoryProvider = {
  provide: AuthRepository,
  useClass: AuthImplRepository,
};

@NgModule({
  providers: [
    loginUseCaseProvider,
    refreshTokenUseCaseProvider,
    recoveyUseCaseProvider,
    userRepositoryProvider,
  ],
  imports: [CommonModule],
})
export class AuthModule {}
