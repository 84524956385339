import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ShortTravelPlanMapper } from './mappers/short-travel-plan.mapper';
import { TravelPlanEntity } from './entities/travel-plan-entity';
import { TravelPlanMapper } from './mappers/travel-plan.mapper';
import { PaymentSummaryEntity } from './entities/payment-summary.entity';
import { PaymentSummaryMapper } from './mappers/payment-summary.mapper';
import { TransactionEntity } from './entities/transaction.entity';
import { TransactionMapper } from './mappers/transaction.mapper';
import { TravelPlanSummaryEntity } from './entities/travel-plan-summary.entity';
import { ContributionMapper } from './mappers/contribution.mapper';
import { ContributionEntity } from './entities/contribution.entity';
import { ContributionModel } from '@domain/models/contribution.model';
import { TravelPlanSummaryMapper } from './mappers/travel-plan-summary.mapper';
import { TravelPlanSummaryModel } from '@domain/models/travel-plan-summary.model';
import { TravelPlanModel, TravelPlanState, TravelPlanShortModel } from '@domain/models/travel-plan.model';
import { TravelPlanRepository } from '@domain/repositories/travel-plan.repository';
import { TransactionModel } from '@domain/models/transaction.model';
import { PaginatedData } from '@base/wrappers/paginated-data';
import { ResponseData } from '@base/wrappers/response-data';
import { PaymentSummaryModel } from '@domain/models/payment-summary.model';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class TravelPlanImplRepository extends TravelPlanRepository {


  private readonly travelPlanUrl: string;
  private shortTravelMapper = new ShortTravelPlanMapper();
  private travelPlanMapper = new TravelPlanMapper();
  private travelPlanSummaryMapper = new TravelPlanSummaryMapper();
  private paymentSummaryMapper = new PaymentSummaryMapper();
  private transactionMapper = new TransactionMapper();
  private travelPlanSummary = new TravelPlanSummaryMapper();
  private contributionMapper = new ContributionMapper();

  constructor(private http: HttpClient) {
    super();
    this.travelPlanUrl = `${environment.uriBase}TravelPlan`;
  }

  override getPlan(id: string): Observable<TravelPlanModel> {
    return this.http
      .get<ResponseData<TravelPlanEntity>>(`${this.travelPlanUrl}/${id}`)
      .pipe(map((result) => this.travelPlanMapper.mapFrom(result.data)));
  }

  override getPlans(
    userId:string,
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<TravelPlanShortModel>> {
    let params = new HttpParams();

    params = params.set('UserId', userId);
    params = params.set('State', state.toString());
    params = params.set('PageNumber', pageNumber.toString());
    params = params.set('PageSize', pageSize.toString());

    return this.http
      .get<ResponseData<PaginatedData<TravelPlanEntity>>>(
        `${this.travelPlanUrl}`,
        { params }
      )
      .pipe(
        map((result) => ({
          ...result.data,
          data: (result.data.data ?? []).map(this.shortTravelMapper.mapFrom),
        }))
      );
  }

  override getPlansSummary(
    userId:string,
    state: TravelPlanState,
    pageNumber: number,
    pageSize: number
  ): Observable<PaginatedData<TravelPlanSummaryModel>> {
    let params = new HttpParams();

    params = params.set('UserId', userId);
    params = params.set('State', state.toString());
    params = params.set('PageNumber', pageNumber.toString());
    params = params.set('PageSize', pageSize.toString());


    return this.http
      .get<ResponseData<PaginatedData<TravelPlanSummaryEntity>>>(
        `${this.travelPlanUrl}/summary`,
        { params }
      )
      .pipe(
        map((result) => ({
          ...result.data,
          data: (result.data.data ?? []).map(
            this.travelPlanSummaryMapper.mapFrom
          ),
        }))
      );
  }

  override getPaymentSummary(planId: string): Observable<PaymentSummaryEntity> {
    return this.http
      .get<ResponseData<PaymentSummaryModel>>(
        `${this.travelPlanUrl}/${planId}/Transaction/contribution/next`
      )
      .pipe(map((result) => this.paymentSummaryMapper.mapFrom(result.data)));
  }

  override getTransactionsList(
    planId: string
  ): Observable<PaginatedData<TransactionModel>> {
    return this.http
      .get<ResponseData<PaginatedData<TransactionEntity>>>(
        `${this.travelPlanUrl}/${planId}/Transaction`
      )
      .pipe(
        map((result) => ({
          ...result.data,
          data: (result.data.data ?? []).map(this.transactionMapper.mapFrom),
        }))
      );
  }

  override getPlanSummary(planId: string): Observable<TravelPlanSummaryModel> {
    return this.http
      .get<ResponseData<TravelPlanSummaryEntity>>(
        `${this.travelPlanUrl}/${planId}/summary`
      )
      .pipe(map((result) =>{  console.log({result}); return this.travelPlanSummary.mapFrom(result.data)}));
  }
  override postTransactionContribution(
    contribution: ContributionModel,
    planId: string
  ): Observable<ContributionModel> {
    return this.http
      .post<ResponseData<ContributionEntity>>(
        `${this.travelPlanUrl}/${planId}/Transaction/contribution`,
        this.contributionMapper.mapTo(contribution)
      )
      .pipe(
        map((result) => {
          return this.contributionMapper.mapFrom(result.data);
        })
      );
  }

  override deletePlan(id: string): Observable<{}> {
   return this.http.delete(`${this.travelPlanUrl}/${id}`)
  }

  // override patchEditPlan(numberContributions: number, monthlyContributionPrice: number): Observable<TravelPlanModel> {
  //   throw new Error('Method not implemented.');
  // }
  override patchEditPlan(
    planId: string,
    numberContributions: number,
    monthlyContributionPrice: number
  ): Observable<TravelPlanModel> {
    const url = `${this.travelPlanUrl}/${planId}`;

    const updatedFields = {
      numberContributions,
      monthlyContributionPrice
    };

    return this.http
      .patch<ResponseData<TravelPlanEntity>>(url, updatedFields)
      .pipe(map((result) => this.travelPlanMapper.mapFrom(result.data)));
  }
}
